@import url(//fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap);
@import url(https://platform.cj.com/design-tokens.css);
:root {
  --nav-comp-height-1: 56px;
  --nav-comp-height-2: 48px;
  --page-title-height-1: 48px;
  --dimensions-height-1: 64px;
}

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 62.5%;
}

@keyframes Loading_fadeIn__3XVWE {
  from {
    opacity: 0;
  }
}

@keyframes Loading_initialLoaderAnimation__sCLUg {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.95);
    opacity: 0.75;
  }
}

.Loading_loadingInContainer__2KbcO {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: none 0s ease 0s 1 normal none :local(runningLoaderAnimation) 0.8s cubic-bezier(0.53, 0.22, 0.85, 0.58) 0s infinite alternate;
  animation: Loading_initialLoaderAnimation__sCLUg 0.8s cubic-bezier(0.53, 0.22, 0.85, 0.58) 0s infinite alternate;
}

:root {
    /* Directly named colors */
    --cj-green: #49c581;
    --dark-grey-blue: #254a5d;
    --greeny-blue: #49c5b1;
    --dark: #151c23;
    --gunmetal: #424c56;
    --black: #333333;
    --warm-grey: #757575;
    --cool-grey: #babbbd;
    --silver: #d8dcdf;
    --nice-blue: #1a6fba;
    --butterscotch: #ffb038;
    --bluey-green: #2bb179;
    --pastel-red: #e25959;
    --white: #ffffff;
  
    /* Maintainable Naming Convention */
    /*   1) order (example: primary, secondary, tertiary, quaternary, quinary, etc) */
    /*   2) role (example: brand, button, table-heading, etc) */
    /*   3) state (example: active, inactive, default, disabled, on-hover, etc) */
    /*   4) css property (example: color, font-family, margin, etc) */
  
    --primary-brand-color: var(--cj-green);
    --primary-button-color: var(--cj-green);
  
    --primary-body-text-color: var(--black);
    --heading-color: var(--black);
    --table-heading-active-color: var(--black);
    --svg-fill-on-hover-color: var(--black);
  
    --secondary-body-text-color: var(--warm-grey);
    --table-heading-defualt-color: var(--warm-grey);
    --table-heading-inactive-color: var(--warm-grey);
    --svg-fill-defualt-color: var(--warm-grey);
  
    --text-link-color: var(--nice-blue);
  
    --panel-color: var(--white);
    --container-color: var(--white);
    --primary-light-text-color: var(--white);
  
    --page-background-color: #f6f6f6;
    --table-row-highlight: #f6f6f6;
  
    --border-color: var(--silver);
  
    --button-background-disabled-color: #edeef0;
  
    --button-text-disabled-color: #a7a7a7;
  
    --badge-color: #6d7684;
    --indicator-color: #6d7684;
  
    --dialog-window-background-color: #4d5051;
  
    --light-text-color: #bebfc0;
  
    --validation-success-color: var(--bluey-green);
  
    --validation-error-color: var(--pastel-red);
  
    --primary-font-family: "Roboto", "-system-ui", "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    --spacing-xs: var(--spacing-2);
    --spacing-small: var(--spacing-4);
    --spacing-medium: var(--spacing-8);
    --spacing-default: var(--spacing-medium);
    --spacing-medium-large: var(--spacing-12);
    --spacing-large: var(--spacing-16);
    --spacing-xl: var(--spacing-24);
    --spacing-2xl: var(--spacing-32);
    --spacing-3xl: var(--spacing-40);
    --spacing-4xl: var(--spacing-48);
    --spacing-5xl: var(--spacing-64);
    --spacing-6xl: var(--spacing-80);
  }
  
.vsx-styled-form * {
  box-sizing: border-box;
}
.vsx-styled-form fieldset p {
  position: relative;
  display: block;
  overflow: hidden;
}
.vsx-styled-form p > label {
  float: left;
  padding: 0 0 0 18px;
}
.vsx-styled-form label {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 500;
  max-width: 100%;
  margin: 0 0 0.4rem;
}
.vsx-styled-form label span {
  font-size: 1.4rem;
  font-weight: 400;
}
.vsx-styled-form fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.vsx-styled-form legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: inherit;
  color: var(--color-text-default);
  border: 0;
  border-bottom: 1px solid #e1e1e1;
}
.vsx-styled-form select,
.vsx-styled-form textarea,
.vsx-styled-form input[type=file],
.vsx-styled-form input[type=text],
.vsx-styled-form input[type=password],
.vsx-styled-form input[type=date],
.vsx-styled-form input[type=datetime],
.vsx-styled-form input[type=datetime-local],
.vsx-styled-form input[type=month],
.vsx-styled-form input[type=time],
.vsx-styled-form input[type=week],
.vsx-styled-form input[type=number],
.vsx-styled-form input[type=email],
.vsx-styled-form input[type=url],
.vsx-styled-form input[type=search],
.vsx-styled-form input[type=tel],
.vsx-styled-form input[type=color] {
  background-color: var(--color-background-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  color: var(--color-text-default);
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  height: 32px;
  line-height: 1.6;
  max-width: 100%;
  padding: 2px 8px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
.vsx-styled-form select:focus,
.vsx-styled-form textarea:focus,
.vsx-styled-form input[type=file]:focus,
.vsx-styled-form input[type=text]:focus,
.vsx-styled-form input[type=password]:focus,
.vsx-styled-form input[type=datetime]:focus,
.vsx-styled-form input[type=datetime-local]:focus,
.vsx-styled-form input[type=date]:focus,
.vsx-styled-form input[type=month]:focus,
.vsx-styled-form input[type=time]:focus,
.vsx-styled-form input[type=week]:focus,
.vsx-styled-form input[type=number]:focus,
.vsx-styled-form input[type=email]:focus,
.vsx-styled-form input[type=url]:focus,
.vsx-styled-form input[type=search]:focus,
.vsx-styled-form input[type=tel]:focus,
.vsx-styled-form input[type=color]:focus {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-styled-form select.input-error,
.vsx-styled-form textarea.input-error,
.vsx-styled-form input.input-error[type=file],
.vsx-styled-form input.input-error[type=text],
.vsx-styled-form input.input-error[type=password],
.vsx-styled-form input.input-error[type=datetime],
.vsx-styled-form input.input-error[type=datetime-local],
.vsx-styled-form input.input-error[type=date],
.vsx-styled-form input.input-error[type=month],
.vsx-styled-form input.input-error[type=time],
.vsx-styled-form input.input-error[type=week],
.vsx-styled-form input.input-error[type=number],
.vsx-styled-form input.input-error[type=email],
.vsx-styled-form input.input-error[type=url],
.vsx-styled-form input.input-error[type=search],
.vsx-styled-form input.input-error[type=tel],
.vsx-styled-form input.input-error[type=color] {
  border-color: var(--color-border-outline-error);
  box-shadow: 0 0 4px rgba(225, 82, 61, 0.4);
}
.vsx-styled-form input[type=radio] + label,
.vsx-styled-form input[type=checkbox] + label {
  font-weight: 400;
  margin: 0 1.6rem 0 0;
}
.vsx-styled-form input[type=radio],
.vsx-styled-form input[type=checkbox] {
  margin: 0.6rem 0.8rem 0.8rem 0;
  line-height: normal;
}
.vsx-styled-form input[type=radio]:focus,
.vsx-styled-form input[type=checkbox]:focus {
  outline: thin dotted 9;
  outline: 4px auto -webkit-focus-ring-color;
  /*   outline-offset: -2px; */
}
.vsx-styled-form input[type=color] {
  border-radius: 2px;
  padding: 0 2px;
  height: 24px;
  width: 24px;
}
.vsx-styled-form input[type=file] {
  display: block;
  font-size: 1.2rem;
  height: auto;
  padding: 4px;
}
.vsx-styled-form input[type=range] {
  display: block;
  padding: 4px;
  width: 100%;
}
.vsx-styled-form optgroup {
  font-weight: bold;
}
.vsx-styled-form output {
  display: block;
  padding-top: 8px;
  font-size: 1.4rem;
  line-height: 1.6;
  color: var(--color-text-default);
}
.vsx-styled-form progress {
  display: block;
  width: 100%;
}
.vsx-styled-form select[multiple],
.vsx-styled-form select[size] {
  height: auto;
}
.vsx-styled-form textarea {
  overflow: auto;
  min-height: 64px;
}
.vsx-styled-form input[disabled],
.vsx-styled-form select[disabled],
.vsx-styled-form textarea[disabled],
.vsx-styled-form input[readonly],
.vsx-styled-form select[readonly],
.vsx-styled-form textarea[readonly] {
  color: #888;
  cursor: not-allowed;
  background-color: #eee;
  border-color: #e1e1e1;
}
.vsx-styled-form input[type=radio][disabled],
.vsx-styled-form input[type=checkbox][disabled],
.vsx-styled-form input[type=radio].disabled,
.vsx-styled-form input[type=checkbox].disabled,
.vsx-styled-form fieldset[disabled] input[type=radio],
.vsx-styled-form fieldset[disabled] input[type=checkbox] {
  cursor: not-allowed;
}
.vsx-styled-form :-ms-input-placeholder {
  color: #999;
  opacity: 1;
}
.vsx-styled-form ::placeholder {
  color: #999;
  opacity: 1;
}
/* @license
 * MyFonts Webfont Build ID 2691808, 2013-11-19T16:06:51-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gibson Light by Canada Type
 * URL: http://www.myfonts.com/fonts/canadatype/gibson/light/

 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2691808
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2011 Rod McDonald. Published by Canada Type. All rights reserved.
 *
 * Â© 2013 MyFonts Inc
*/
@font-face {
  font-family: 'gibson-light';
  src: url(/static/media/gibson-light-webfont.a87665d3.woff2) format('woff2'),
    url(/static/media/gibson-light-webfont.ba481e12.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

/* @import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800'); */

/*
GLOBAL COLOR VARIABLES:
border-color: #c8ccce;
Brand Primary: #49c5b1
Brand Dark Blue: #151c23
Pink <code>: #eb3366
*/

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar;
}

body {
  background-color: var(--color-background-page);
  color: var(--color-text-default);
  font-family: 'Roboto', -system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

::selection {
  background-color: rgba(73, 197, 176, 0.4);
  color: #000;
  text-shadow: none;
}

::-moz-selection {
  background-color: rgba(73, 197, 176, 0.4);
  color: #000;
  text-shadow: none;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

.vsx-toaster {
  width: 100%;
  z-index: 9999;
  position: fixed;
  pointer-events: none;
  bottom: 8px;
  --vsx-toast-init-position: 80px;
}
.vsx-toaster.vsx-toaster-top {
  --vsx-toast-init-position: -80px;
  bottom: unset;
  top: 8px;
}
.vsx-toaster .vsx-toast {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: opacity 150ms ease-out, transform 100ms ease-in-out;
  animation: slide-in 250ms ease-in-out;
  position: absolute;
  pointer-events: all;
}
.vsx-toaster .vsx-toast.vsx-toast-dismissed {
  opacity: 0;
}
@keyframes slide-in {
  0% {
    opacity: 0;
    margin-left: -40px;
    margin-top: var(--vsx-toast-init-position);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    margin-left: 0;
    margin-top: 0;
  }
}
.vsx-notification {
  box-sizing: border-box;
  background: var(--color-background-default);
  border-radius: 3px;
}
.vsx-notification.vsx-notification-border {
  outline: 1px solid var(--color-border-light);
}
.vsx-notification.vsx-notification-border-left-info {
  border-left: 4px solid var(--color-icon-default);
}
.vsx-notification.vsx-notification-border-left-success {
  border-left: 4px solid var(--color-icon-success);
}
.vsx-notification.vsx-notification-border-left-warning {
  border-left: 4px solid var(--color-icon-critical);
}
.vsx-notification.vsx-notification-border-left-alert {
  border-left: 4px solid #d39053;
  border-left: 4px solid var(--color-icon-alert, #d39053);
}
.vsx-notification.vsx-notification-shadow-small {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}
.vsx-notification.vsx-notification-shadow-large {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}
.vsx-notification .vsx-notification-dismiss-icon {
  cursor: pointer;
  color: var(--color-text-subdued);
}
.vsx-notification .vsx-notification-dismiss-icon:active {
  color: var(--color-text-default);
  box-shadow: 0 0 0 2px var(--color-background-light-2);
}
.vsx-notification .vsx-notification-dismiss-icon:hover {
  background-color: var(--color-background-light-2);
  color: var(--color-text-default);
}
.vsx-notification .vsx-notification-children {
  font-size: var(--font-font-size-13);
  color: var(--color-text-default);
  flex-grow: 1;
  align-self: center;
}
.vsx-notification .vsx-notification-icon {
  font-size: var(--icon-size);
}
.vsx-notification .vsx-notification-icon.info {
  color: var(--color-icon-default);
}
.vsx-notification .vsx-notification-icon.success {
  color: var(--color-icon-success);
}
.vsx-notification .vsx-notification-icon.warning {
  color: var(--color-icon-critical);
}
.vsx-notification .vsx-notification-icon.alert {
  color: #d39053;
  color: var(--color-icon-alert, #d39053);
}
.vsx-mdi-icon {
  display: inline-flex;
  font-size: 1em;
  width: 1em;
  height: 1em;
  place-items: center;
  place-content: center;
}
.vsx-mdi-icon > svg {
  margin: 0px auto;
}
.vsx-mdi-icon.vsx-mdi-icon-clickable {
  cursor: pointer;
  transition: all 0.1s;
}
.vsx-mdi-icon.vsx-mdi-icon-clickable:hover {
  color: white;
  background-color: grey;
  border-radius: 3px;
}
.vsx-mdi-icon .vsx-beat {
  animation-name: vsx-beat;
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-timing-function: ease-in-out;
  animation-timing-function: var(--vsx-animation-timing, ease-in-out);
}
.vsx-mdi-icon .vsx-bounce {
  animation-name: vsx-bounce;
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-timing-function: var(--vsx-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}
.vsx-mdi-icon .vsx-fade {
  animation-name: vsx-fade;
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-timing-function: var(--vsx-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}
.vsx-mdi-icon .vsx-beat-fade {
  animation-name: vsx-beat-fade;
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-timing-function: var(--vsx-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}
.vsx-mdi-icon .vsx-flip {
  animation-name: vsx-flip;
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-timing-function: ease-in-out;
  animation-timing-function: var(--vsx-animation-timing, ease-in-out);
}
.vsx-mdi-icon .vsx-shake {
  animation-name: vsx-shake;
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-timing-function: linear;
  animation-timing-function: var(--vsx-animation-timing, linear);
}
.vsx-mdi-icon .vsx-spin {
  animation-name: vsx-spin;
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  animation-duration: 2s;
  animation-duration: var(--vsx-animation-duration, 2s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-timing-function: linear;
  animation-timing-function: var(--vsx-animation-timing, linear);
}
.vsx-mdi-icon .vsx-reverse {
  --vsx-animation-direction: reverse;
}
.vsx-mdi-icon .vsx-pulse,
.vsx-mdi-icon .vsx-spin-pulse {
  animation-name: vsx-spin;
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-timing-function: steps(8);
  animation-timing-function: var(--vsx-animation-timing, steps(8));
}
@media (prefers-reduced-motion: reduce) {
  .vsx-mdi-icon .vsx-beat,
.vsx-mdi-icon .vsx-bounce,
.vsx-mdi-icon .vsx-fade,
.vsx-mdi-icon .vsx-beat-fade,
.vsx-mdi-icon .vsx-flip,
.vsx-mdi-icon .vsx-pulse,
.vsx-mdi-icon .vsx-shake,
.vsx-mdi-icon .vsx-spin,
.vsx-mdi-icon .vsx-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes vsx-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.25);
    transform: scale(var(--vsx-beat-scale, 1.25));
  }
}
@keyframes vsx-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
    transform: scale(var(--vsx-bounce-start-scale-x, 1.1), var(--vsx-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-0.5em);
    transform: scale(var(--vsx-bounce-jump-scale-x, 0.9), var(--vsx-bounce-jump-scale-y, 1.1)) translateY(var(--vsx-bounce-height, -0.5em));
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
    transform: scale(var(--vsx-bounce-land-scale-x, 1.05), var(--vsx-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-0.125em);
    transform: scale(1, 1) translateY(var(--vsx-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes vsx-fade {
  50% {
    opacity: 0.4;
    opacity: var(--vsx-fade-opacity, 0.4);
  }
}
@keyframes vsx-beat-fade {
  0%, 100% {
    opacity: 0.4;
    opacity: var(--vsx-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.125);
    transform: scale(var(--vsx-beat-fade-scale, 1.125));
  }
}
@keyframes vsx-flip {
  50% {
    transform: rotate3d(0, 1, 0, -180deg);
    transform: rotate3d(var(--vsx-flip-x, 0), var(--vsx-flip-y, 1), var(--vsx-flip-z, 0), var(--vsx-flip-angle, -180deg));
  }
}
@keyframes vsx-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes vsx-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.vsx-table-container {
  border: 1px solid #c8ccce;
  border-radius: 3px;
  padding: 16px;
  margin: 0 0 32px;
}
.vsx-table-container .vsx-table {
  margin-bottom: 0;
}

.vsx-table-title {
  font-weight: 500;
  font-size: var(--font-font-size-16);
  border-bottom: 0px solid #c8ccce;
  margin-bottom: 8px;
}

.vsx-table {
  border-collapse: collapse;
  text-align: left;
  margin: 0 0 32px;
  width: 100%;
  font-size: var(--font-font-size-14);
}
.vsx-table:last-child {
  margin-bottom: 0;
}
.vsx-table > tbody > tr:hover {
  background-color: #fafafa;
}
.vsx-table .vsx-thead .vsx-cell {
  border-bottom: 1px solid #c8ccce;
  vertical-align: middle;
  min-height: 32px;
  font-size: var(--font-font-size-13);
}
.vsx-table .vsx-tfoot .vsx-cell {
  border-top: 1px solid #c8ccce;
  font-size: var(--font-font-size-13);
}
.vsx-table .vsx-cell {
  border-top: 1px solid #eee;
  vertical-align: middle;
  padding: 4px;
  margin: 0;
  min-height: 32px;
}
.vsx-table .vsx-cell-right {
  text-align: right;
}
.vsx-table .vsx-cell-center {
  text-align: center;
}
.vsx-table .vsx-cell-nowrap {
  white-space: nowrap;
}

@media (max-width: 600px) {
  .vsx-cell {
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vsx-cell:first-child {
    max-width: 100px;
  }
}
.vsx-spinner.vsx-spinner-small {
  padding: 1em;
}

.vsx-spinner.vsx-spinner-large {
  padding: 1.2em;
}

.vsx-spinner.vsx-spinner-extra-large {
  padding: 1.4em;
}

.vsx-spinner-button span {
  padding: 0.3em;
}

.vsx-spinner-no-pad.vsx-spinner-no-pad {
  padding: 0;
}

.vsx-spinner-inline-block {
  display: inline-block;
}

.vsx-link {
  color: var(--color-text-link-default);
  cursor: pointer;
  text-decoration: unset;
}
.vsx-link:hover {
  color: var(--color-text-link-hover);
  text-decoration: underline;
}
.vsx-link:disabled {
  color: var(--color-text-subdued-light);
}
.vsx-btn {
  font-family: "Roboto";
  box-sizing: border-box;
  border-collapse: separate;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: var(--color-text-default);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  height: 32px;
  min-width: min-content;
  overflow: hidden;
  padding: 4px 12px;
  text-align: center;
  text-shadow: none;
  transition: 0.2s all ease-in-out;
  vertical-align: middle;
  width: auto;
  white-space: nowrap;
  position: relative;
}
.vsx-btn:focus-visible {
  outline: auto;
}
.vsx-btn:visited {
  text-decoration: underline;
}
.vsx-btn:disabled {
  cursor: not-allowed;
}
.vsx-btn .vsx-btn-child.vsx-btn-child-hidden {
  opacity: 0;
}
.vsx-btn .vsx-btn-spinner {
  position: absolute;
  display: flex;
}
.vsx-btn.uppercase {
  text-transform: uppercase;
}
.vsx-btn.sm {
  font-size: 1rem;
  height: auto;
  line-height: 1;
  padding: 6px 8px;
}
.vsx-btn.lg {
  padding: 12px 28px;
  height: 40px;
  line-height: 1;
}
.vsx-btn.primary {
  border-color: var(--color-background-button-primary-default);
  background: var(--color-background-button-primary-default);
  color: var(--color-text-white);
}
.vsx-btn.primary:focus-visible {
  outline-color: var(--color-background-primary-default);
}
.vsx-btn.primary:hover:not(:disabled) {
  background: var(--color-background-button-primary-hovered);
}
.vsx-btn.primary:active:not(:disabled) {
  background: var(--color-background-button-primary-active);
  box-shadow: 0 0 0 2px var(--color-background-primary-light);
}
.vsx-btn.primary:disabled {
  background: var(--color-background-button-primary-disabled);
  color: var(--color-deprecating-vs-primary-text-disabled);
}
.vsx-btn.secondary {
  background: var(--color-background-default);
  border-color: var(--color-border-outline-subdued);
  color: var(--color-text-subdued);
}
.vsx-btn.secondary:hover:not(:disabled) {
  border-color: var(--color-border-outline-dark);
  color: var(--color-text-default);
}
.vsx-btn.secondary:active:not(:disabled) {
  border-color: var(--color-border-outline-dark);
  color: var(--color-text-default);
  box-shadow: 0 0 0 2px var(--color-background-light-2);
}
.vsx-btn.secondary:disabled {
  background: var(--color-background-disabled);
  border-color: var(--color-border-light);
  color: var(--color-text-subdued-light);
}
.vsx-btn.tertiary {
  border-color: transparent;
  background: var(--color-background-transparent);
  color: var(--color-text-subdued);
}
.vsx-btn.tertiary:hover:not(:disabled) {
  background: var(--color-background-light-2);
  color: var(--color-text-default);
}
.vsx-btn.tertiary:active:not(:disabled) {
  background: var(--color-background-light-2);
  box-shadow: 0 0 0 2px var(--color-background-light-3);
}
.vsx-btn.tertiary:disabled {
  color: var(--color-text-subdued-light);
}
.vsx-btn.critical {
  border-color: #B01900;
  border-color: var(--background-button-critical-default, #B01900);
  background: #B01900;
  background: var(--background-button-critical-default, #B01900);
  color: var(--color-text-white);
}
.vsx-btn.critical:focus-visible {
  outline: 1px solid #911500;
  outline: 1px solid var(--background-button-critical-hovered, #911500);
}
.vsx-btn.critical:hover:not(:disabled) {
  background: #911500;
  background: var(--background-button-critical-hovered, #911500);
}
.vsx-btn.critical:active:not(:disabled) {
  outline: 2px solid #E7C7C7;
  outline: 2px solid var(--background-button-critical-pressed, #E7C7C7);
}
.vsx-btn.critical:disabled {
  background: #E78476;
  background: var(--background-button-critical-disabled, #E78476);
  border-color: #E78476;
  border-color: var(--background-button-critical-disabled, #E78476);
}
.vsx-btn.link {
  border-color: transparent;
  background: var(--color-background-transparent);
  color: var(--color-text-link-default);
}
.vsx-btn.link:hover:not(:disabled) {
  background: var(--color-background-light-2);
  color: var(--color-text-link-hover);
}
.vsx-btn.link:active:not(:disabled) {
  background: var(--color-background-light-2);
  box-shadow: 0 0 0 2px var(--color-background-light-3);
}
.vsx-btn.link:disabled {
  color: var(--color-text-subdued-light);
}
.vsx-icon-btn {
  min-width: 32px;
  min-height: 32px;
  height: 32px;
  font-size: 20px;
  padding: 0;
}
.vsx-icon-btn.free-size {
  min-width: unset;
  min-height: unset;
  font-size: unset;
  line-height: unset;
  width: unset;
  height: unset;
}
.vsx-icon-btn.tertiary {
  border-radius: 3px;
}
.vsx-icon-btn.tertiary.rounded {
  border-radius: var(--border-radius-circle);
}
.vsx-icon-btn > .vsx-mdi-icon {
  align-self: center;
  margin: 0 auto;
}
.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:currentColor;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:currentColor;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:currentColor;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:currentColor;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
.vsx-popover-wrapper, .vsx-popover-reference {
  display: inline-flex;
  flex-direction: column;
}

.vsx-popover {
  box-shadow: 1px 4px 8px 1px var(--color-background-backdrop);
  text-align: left;
}
.vsx-popover.vsx-popover-unstyled > .tippy-content {
  padding: 0 !important;
  border-left: none !important;
}
.vsx-popover.vsx-popover-animate {
  animation: fade-in 0.2s ease-out;
}
.vsx-popover.vsx-popover-success {
  --vsx-popover-colored-border-color: var(--color-background-button-primary-default);
}
.vsx-popover.vsx-popover-info {
  --vsx-popover-colored-border-color: var(--color-icon-default);
}
.vsx-popover.vsx-popover-warning {
  --vsx-popover-colored-border-color: var(--color-icon-critical);
}
.vsx-popover .tippy-content {
  color: var(--color-text-default);
  background-color: var(--color-background-default);
  padding: 12px;
  border-radius: 3px;
  border-left: 4px solid var(--vsx-popover-colored-border-color);
}
.vsx-popover .tippy-content > div:first-child {
  display: flex;
  place-content: center;
  place-items: center;
}
.vsx-popover[data-placement^=bottom] > .tippy-arrow:before {
  border-bottom-color: var(--color-background-default);
}
.vsx-popover[data-placement^=top] > .tippy-arrow:before {
  border-top-color: var(--color-background-default);
}
.vsx-popover[data-placement^=left] > .tippy-arrow:before {
  border-left-color: var(--color-background-default);
}
.vsx-popover[data-placement^=right] > .tippy-arrow:before {
  border-right-color: var(--vsx-popover-colored-border-color);
}
.vsx-popover .tippy-arrow {
  color: var(--color-background-default);
}

.vsx-tooltip.vsx-popover {
  --vsx-popover-colored-border-color: var(--color-icon-on-default);
}
.vsx-tooltip.vsx-popover .tippy-content {
  color: var(--color-white);
  background-color: var(--color-icon-on-default);
  border-left: unset;
  padding: 7px 8px;
  font-size: 11px;
  font-weight: normal;
}
.vsx-tooltip.vsx-popover[data-placement^=bottom] > .tippy-arrow:before {
  border-bottom-color: var(--vsx-popover-colored-border-color);
}
.vsx-tooltip.vsx-popover[data-placement^=top] > .tippy-arrow:before {
  border-top-color: var(--vsx-popover-colored-border-color);
}
.vsx-tooltip.vsx-popover[data-placement^=left] > .tippy-arrow:before {
  border-left-color: var(--vsx-popover-colored-border-color);
}
.vsx-tooltip.vsx-popover[data-placement^=right] > .tippy-arrow:before {
  border-right-color: var(--vsx-popover-colored-border-color);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.vsx-error-page {
  max-width: min(100%, 768px);
  margin: 0 auto;
}
.vsx-flexpage {
  box-sizing: border-box;
  overflow: auto;
}
/* START BOOTSTRAP STYLE TRANSFER (need to cleanup) */
.panel {
  margin-bottom: 2.4rem;
  background-color: var(--color-background-default);
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 3px -1px rgba(0, 0, 0, 0.08);
}
.panel-body {
  padding: 16px;
}
.panel-heading {
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 12px 16px;
}
.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}
.panel-title {
  color: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}
.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit;
}
.panel-footer {
  /*   background-color: #f9f9f9; */
  /*   border-top: 1px solid #ddd; */
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 1.3rem;
  padding: 16px;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel
  > .panel-collapse
  > .list-group:first-child
  .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel
  > .panel-heading
  + .panel-collapse
  > .list-group
  .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}

.vsx-panel-default > .panel-heading {
  color: var(--color-text-default);
  background-color: #f5f5f5;
  border-color: #c8ccce;
}

.panel-body:before,
.panel-body:after {
  display: table;
  content: ' ';
}

.panel-body:after {
  clear: both;
}
/* END BOOTSTRAP STYLES */

.cj-panel.panel-heading {
  background-color: var(--color-background-default);
}

.cj-panel.panel-footer {
  background-color: var(--color-background-default);
}

.cj-panel.panel-heading legend {
  text-transform: uppercase;
  color: #bbb;
  font-weight: 500;
  font-size: 1.6rem;
  border: none;
  padding-top: 0px;
  margin-bottom: 0px;
}

.cj-panel.panel .cj-panel.panel-body:not(:last-child) {
  border-bottom: 1px solid transparent;
  border-color: #c8ccce;
}
.cj-panel-body-padding-wide.panel-body {
  padding: 32px;
}

.cj-panel-body-padding-none.panel-body {
  padding: 0;
}

.vsx-top-nav-layout {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
}
.vsx-top-nav-layout.vsx-top-nav-layout-absolute {
  position: absolute;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav {
  color: white;
  box-sizing: border-box;
  background-color: var(--color-background-dark-2);
  flex-basis: 64px;
  flex-shrink: 0;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-actions {
  fill: #bebfc0;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-actions .vsx-top-nav-layout-cancel-action {
  color: #fff;
  opacity: 0.64;
  background: hsla(206deg, 3%, 45%, 0.1);
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-actions .vsx-top-nav-layout-cancel-action:hover {
  opacity: 1;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-logo {
  width: 32px;
  height: 32px;
  fill: white;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-title {
  font-size: 20px;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-action {
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  color: #fff;
  opacity: 0.64;
  background: hsla(206deg, 3%, 45%, 0.1);
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-action:hover {
  opacity: 1;
}
.vsx-top-nav-layout .vsx-top-nav-layout-content {
  box-sizing: border-box;
  padding: 24px;
  background-color: var(--color-background-light-2);
  width: 100%;
  flex-grow: 1;
  overflow: scroll;
}
.vsx-cj-logo-padded {
  padding: 4px;
}

.vsx-cj-logo-small {
  width: 24px;
  height: 24px;
}

.vsx-cj-logo-medium {
  width: 32px;
  height: 32px;
}

.vsx-cj-logo-large {
  width: 60px;
  height: 60px;
}
/* BOOTSTRAP 3.3.6ish MODAL STYLES - NEED TO REMOVE/REPLACE */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  top: 80px;
  background-color: var(--color-background-default);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 3px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  transition: 0.2s all ease-in-out;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-open {
  overflow: hidden;
}

.modal-header {
  padding: 15px 25px 15px 25px;
}

.modal-header-grid {
  display: grid;
  grid-template-columns: 1fr 36px;
  align-items: center;
}

.modal-header h1 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  position: relative;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  flex-grow: 1;
}

.modal-footer {
  padding: 15px 25px 15px 25px;
  text-align: right;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}

.modal-header:after,
.modal-footer:after {
  clear: both;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
  .modal-md {
    width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.vsx-badge {
  font-weight: 600;
  font-size: 10px;
  border-radius: 0.2rem;
  display: inline-block;
  color: var(--color-text-white);
  padding: 0.2rem 0.4rem;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--color-background-dark-2);
}
.vsx-badge.uppercase {
  text-transform: uppercase;
}
.vsx-badge.var-cj-green {
  background-color: var(--color-background-button-primary-default);
  color: var(--color-text-decorative-var-1);
}
.vsx-badge.var-1 {
  background-color: var(--color-background-decorative-light-var-1);
  color: var(--color-text-decorative-var-1);
}
.vsx-badge.var-2 {
  background-color: var(--color-background-decorative-light-var-2);
  color: var(--color-text-decorative-var-2);
}
.vsx-badge.var-3 {
  background-color: var(--color-background-decorative-light-var-3);
  color: var(--color-text-decorative-var-3);
}
.vsx-badge.var-4 {
  background-color: var(--color-background-decorative-light-var-4);
  color: var(--color-text-decorative-var-4);
}
.vsx-badge.var-5 {
  background-color: var(--color-background-decorative-light-var-5);
  color: var(--color-text-decorative-var-5);
}
.vsx-badge.var-6 {
  background-color: var(--color-background-decorative-light-var-6);
  color: var(--color-text-decorative-var-6);
}
.vsx-badge.var-7 {
  background-color: var(--color-background-decorative-light-var-7);
  color: var(--color-text-decorative-var-7);
}
.vsx-badge.var-8 {
  background-color: var(--color-background-decorative-light-var-8);
  color: var(--color-text-decorative-var-8);
}
.vsx-badge.var-9 {
  background-color: var(--color-background-decorative-light-var-9);
  color: var(--color-text-default);
}
.vsx-badge.var-10 {
  background-color: var(--color-background-decorative-light-var-10);
  color: var(--color-text-default);
}
.vsx-badge.var-dark-1 {
  background-color: var(--color-background-decorative-var-1);
  color: var(--color-text-white);
}
.vsx-badge.var-dark-2 {
  background-color: var(--color-background-decorative-var-2);
  color: var(--color-text-white);
}
.vsx-badge.var-dark-3 {
  background-color: var(--color-background-decorative-var-3);
  color: var(--color-text-white);
}
.vsx-text-area-element {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s ease-in-out;
  padding: 10px;
  min-height: 60px;
}
.vsx-text-area-element:focus {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-text-area-element.error {
  border-color: var(--color-background-critical);
}
.vsx-text-area-element.error:focus {
  box-shadow: 0 0 6px var(--color-background-critical);
  border-color: var(--color-background-critical);
}
.vsx-text-area-element:focus:disabled, .vsx-text-area-element:focus[readonly] {
  outline: var(--color-border-light);
  border-color: var(--color-border-light);
  box-shadow: 0 0 0 1px var(--color-border-light);
}
.vsx-text-area-element:disabled, .vsx-text-area-element[readonly] {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-text-area-element[data-resize=both] {
  resize: both;
}
.vsx-text-area-element[data-resize=none] {
  resize: none;
}
.vsx-text-area-element[data-resize=horizontal] {
  resize: horizontal;
}
.vsx-text-area-element[data-resize=vertical] {
  resize: vertical;
}
.vsx-react-select.vsx-react-select.vsx-react-select.vsx-react-select-error {
  box-shadow: 0 0 5px var(--color-text-critical);
}
.vsx-react-select.vsx-react-select.vsx-react-select.vsx-react-select-error .vsx-react-select-control {
  border-color: var(--color-text-critical);
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-control {
  border: 1px solid #c8ccce;
  min-height: 30px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-control-disabled {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-react-select.vsx-react-select.vsx-react-select.focus-styles .vsx-react-select-control-focused {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-indicators-container {
  min-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-clear-indicator {
  padding: 0;
  margin: 0 3px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-clear-indicator .vsx-mdi-icon:hover {
  color: white;
  background-color: grey;
  border-radius: 3px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-loading-indicator {
  padding: 0;
  margin: 0 6px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-dropdown-indicator {
  padding: 0 2px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-indicator-separator {
  margin-top: 0;
  margin-bottom: 0;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-multivalue-remove:hover {
  color: unset;
  background-color: unset;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-multivalue-remove .vsx-mdi-icon:hover {
  color: white;
  background-color: grey;
  border-radius: 3px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-menu-list {
  padding-top: 0;
  padding-bottom: 0;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-option-selected {
  color: unset;
}
/** @prettier */
.mdi-icon {
  fill: currentColor;
}

.vsx-cj-icon {
  height: 24px;
  width: 24px;
}

.vsx-cjform {
  font-size: var(--font-font-size-12);
}
.vsx-cjform .vsx-cjform-grid {
  grid-gap: 16px 12px;
  gap: 16px 12px;
}
.vsx-cjform .vsx-cjform-fullwidth {
  grid-column: 1/-1;
}
.vsx-cjform .vsx-cjform-heading {
  font-size: var(--font-font-size-16);
  font-weight: var(--font-font-weight-medium);
}
.vsx-cjform .vsx-cjform-subheading .vsx-cjform-subheading-heading {
  font-size: var(--font-font-size-14);
  font-weight: var(--font-font-weight-medium);
}
.vsx-cjform .vsx-cjform-subheading .vsx-cjform-subheading-description {
  color: var(--color-text-subdued);
  font-size: var(--font-font-size-13);
  line-height: 1.6;
}

.vsx-cjform-required-indicator {
  color: var(--color-text-critical);
}

.vsx-cjform-input-hidden {
  display: none !important;
}

.vsx-cjform-generic-input-wrapper {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s ease-in-out;
}
.vsx-cjform-generic-input-wrapper > input {
  outline: none;
  border: none;
  font: inherit;
  flex-grow: 1;
  background: unset;
  min-width: 0;
}
.vsx-cjform-generic-input-wrapper:has(input:focus) {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-cjform-generic-input-wrapper.error {
  border-color: var(--color-background-critical);
}
.vsx-cjform-generic-input-wrapper.error:has(input:focus) {
  box-shadow: 0 0 6px var(--color-background-critical);
  border-color: var(--color-background-critical);
}
.vsx-cjform-generic-input-wrapper:has(input:focus:disabled), .vsx-cjform-generic-input-wrapper:has(input:focus[readonly]) {
  outline: var(--color-border-light);
  border-color: var(--color-border-light);
  box-shadow: 0 0 0 1px var(--color-border-light);
}
.vsx-cjform-generic-input-wrapper:has(input:disabled), .vsx-cjform-generic-input-wrapper:has(input[readonly]) {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-cjform-generic-input-wrapper:has(input:disabled) > input:disabled, .vsx-cjform-generic-input-wrapper:has(input:disabled) > input[readonly], .vsx-cjform-generic-input-wrapper:has(input[readonly]) > input:disabled, .vsx-cjform-generic-input-wrapper:has(input[readonly]) > input[readonly] {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}

.vsx-cjform-textarea-input {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s ease-in-out;
  height: unset;
  padding: 10px;
  resize: none;
}
.vsx-cjform-textarea-input:focus {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-cjform-textarea-input.error {
  border-color: var(--color-background-critical);
}
.vsx-cjform-textarea-input.error:focus {
  box-shadow: 0 0 6px var(--color-background-critical);
  border-color: var(--color-background-critical);
}
.vsx-cjform-textarea-input:focus:disabled, .vsx-cjform-textarea-input:focus[readonly] {
  outline: var(--color-border-light);
  border-color: var(--color-border-light);
  box-shadow: 0 0 0 1px var(--color-border-light);
}
.vsx-cjform-textarea-input:disabled, .vsx-cjform-textarea-input[readonly] {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}

.vsx-cjform-select-input {
  width: auto;
}

.vsx-cjform-radio-input,
.vsx-cjform-checkbox-input {
  margin: 0;
}

.vsx-cjform-tab.vsx-tab-content.standard {
  padding: 16px 0;
}

.vsx-cjform-tab-error-label {
  transform: scale(1.3);
}

.vsx-cjform-section {
  --separator: 1px solid var(--color-border-light);
  border-top: var(--separator);
  border-bottom: var(--separator);
}
.vsx-cjform-section:not(.disable-form-page-block-adjust) {
  margin-left: -32px;
  margin-right: -32px;
}
.vsx-cjform-section .vsx-cjform-section-title {
  font-size: var(--font-font-size-14);
  font-weight: var(--font-font-weight-medium);
  cursor: pointer;
}
.vsx-cjform-section .vsx-cjform-section-children {
  padding: 16px 32px;
}
.vsx-cjform-section[data-expanded=false] .vsx-cjform-section-children {
  display: none !important;
}

.vsx-cjform-section + .vsx-cjform-section {
  margin-top: -16px;
}

.vsx-cjform-section-no-bottom-border,
.vsx-cjform-section:has(+ .vsx-cjform-section) {
  border-bottom-width: 0;
}
.vsx-menu {
  background: var(--color-background-default);
  color: var(--color-text-default);
  border-radius: 3px;
}
.vsx-menu .vsx-menu-option-wrapper {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.vsx-menu .vsx-menu-option-wrapper .vsx-popover-reference {
  width: 100%;
}
.vsx-menu .vsx-menu-option-wrapper:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.vsx-menu .vsx-menu-option-wrapper:first-child .vsx-menu-option {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.vsx-menu .vsx-menu-option-wrapper:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.vsx-menu .vsx-menu-option-wrapper:last-child .vsx-menu-option {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.vsx-menu .vsx-menu-option {
  display: block;
  width: 100%;
  text-align: left;
  background: unset;
  outline: none;
  border: none;
  padding: 0 6px;
  line-height: 32px;
  cursor: pointer;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: var(--color-text-default);
}
.vsx-menu .vsx-menu-option[data-selected=true], .vsx-menu .vsx-menu-option:hover:not(:disabled) {
  background: var(--color-background-light-2);
}
.vsx-menu .vsx-menu-option:hover:not(:disabled) {
  color: var(--hoverColor);
}
.vsx-menu .vsx-menu-option:active:not(:disabled) {
  background: var(--color-background-light-3);
}
.vsx-menu .vsx-menu-option:disabled {
  color: unset;
  cursor: not-allowed;
  opacity: 0.4;
}
.vsx-tab-header {
  position: relative;
  display: flex;
  background-color: white;
  overflow-x: hidden;
  flex-grow: 1;
}

.vsx-tab-header-container {
  border-bottom: 1px solid #e1e1e1;
  background-color: white;
}

.vsx-tab-header.vsx-tab-header-floating {
  position: fixed;
  box-shadow: 0 1px 2px 0 rgba(136, 136, 136, 0.15);
}

.vsx-tab-label {
  padding-bottom: 4px;
  background-color: var(--color-background-default);
  transition: 0.2s color ease-in-out;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
}
.vsx-tab-label:not(.vsx-tab-label-no-border) {
  border-right: 1px solid #e1e1e1;
}
.vsx-tab-label[data-tab-visible=false] {
  opacity: 0;
}

.vsx-tab-label.vsx-tab-label-disabled {
  color: #cecece;
  background-color: white;
}

.vsx-tab-label.vsx-tab-label-clicked {
  color: var(--color-text-default);
  padding-bottom: 0px;
}

.vsx-tab-label-content.standard {
  white-space: nowrap;
  padding: 12px 32px 8px;
  text-align: center;
}

.vsx-tab-content.standard {
  padding: 16px;
}

.vsx-tab-header-menu {
  padding: 8px 16px 8px;
  height: 100%;
  border-left: 1px solid #e1e1e1;
}
.vsx-tab-header-menu .vsx-popover-reference {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  outline-offset: -1px;
}
.vsx-tab-header-menu .vsx-popover-reference .vsx-tab-header-menu-text {
  padding: 0 12px;
  cursor: default;
  color: rgb(136, 136, 136);
}
.vsx-tab-header-menu .vsx-popover-reference .vsx-tab-header-menu-text.vsx-tab-header-menu-more-selected {
  color: var(--color-text-default);
}

.vsx-tab-header-menu-popover .vsx-menu-option {
  background: unset !important;
  color: rgb(136, 136, 136) !important;
  border-right: 4px solid white;
}
.vsx-tab-header-menu-popover .vsx-menu-option[data-selected=true] {
  color: var(--color-text-default) !important;
  background: unset !important;
  border-right: 4px solid rgb(73, 197, 177);
}
.vsx-field {
  border: unset;
  padding: 0;
  margin: 0;
}
.vsx-field:not(.vsx-field-unset-font-size) {
  font-size: 12px;
}

.vsx-field-label:not(.vsx-field-label-normal-weight) {
  font-weight: var(--font-font-weight-medium);
}

.vsx-field-required-sign {
  color: var(--color-text-critical);
}

.vsx-field-info-error {
  color: var(--color-text-critical);
}

.vsx-field-info-help {
  color: #777;
}

.vsx-field-info-row {
  line-height: 1.6;
  margin-top: -0.3em;
}

.vsx-field-optional-label {
  color: #777;
  font-weight: unset;
  font-style: italic;
}
.vsx-input-element {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s ease-in-out;
}
.vsx-input-element:focus {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-input-element.error {
  border-color: var(--color-background-critical);
}
.vsx-input-element.error:focus {
  box-shadow: 0 0 6px var(--color-background-critical);
  border-color: var(--color-background-critical);
}
.vsx-input-element:focus:disabled, .vsx-input-element:focus[readonly] {
  outline: var(--color-border-light);
  border-color: var(--color-border-light);
  box-shadow: 0 0 0 1px var(--color-border-light);
}
.vsx-input-element:disabled, .vsx-input-element[readonly] {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}

.vsx-input-container {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s ease-in-out;
}
.vsx-input-container > input {
  outline: none;
  border: none;
  font: inherit;
  flex-grow: 1;
  background: unset;
  min-width: 0;
}
.vsx-input-container:has(input:focus) {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-input-container.error {
  border-color: var(--color-background-critical);
}
.vsx-input-container.error:has(input:focus) {
  box-shadow: 0 0 6px var(--color-background-critical);
  border-color: var(--color-background-critical);
}
.vsx-input-container:has(input:focus:disabled), .vsx-input-container:has(input:focus[readonly]) {
  outline: var(--color-border-light);
  border-color: var(--color-border-light);
  box-shadow: 0 0 0 1px var(--color-border-light);
}
.vsx-input-container:has(input:disabled), .vsx-input-container:has(input[readonly]) {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-input-container:has(input:disabled) > input:disabled, .vsx-input-container:has(input:disabled) > input[readonly], .vsx-input-container:has(input[readonly]) > input:disabled, .vsx-input-container:has(input[readonly]) > input[readonly] {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.rdrDateDisplay{
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplayItem{
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem{
    margin-left: 0.833em;
  }

.rdrDateDisplayItem input{
    text-align: inherit
  }

.rdrDateDisplayItem input:disabled{
      cursor: default;
    }

.rdrDateDisplayItemActive{}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.rdrMonthAndYearPickers{
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrMonthPicker{}

.rdrYearPicker{}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrPprevButton {}

.rdrNextButton {}

.rdrMonths{
  display: flex;
}

.rdrMonthsVertical{
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div{
  display: flex;
  flex-direction: row;
}

.rdrMonth{
  width: 27.667em;
}

.rdrWeekDays{
  display: flex;
}

.rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
}

.rdrDays{
  display: flex;
  flex-wrap: wrap;
}

.rdrDateDisplayWrapper{}

.rdrMonthName{}

.rdrInfiniteMonths{
  overflow: auto;
}

.rdrDateRangeWrapper{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
    outline: none;
  }

.rdrDateInput .rdrWarning {
    position: absolute;
    font-size: 1.6em;
    line-height: 1.6em;
    top: 0;
    right: .25em;
    color: #FF0000;
  }

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span{
    color: #1d2429;
  }

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  pointer-events: none;
}

.rdrInRange{}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  pointer-events: none;
}

.rdrDayHovered{}

.rdrDayActive{}

.rdrDateRangePickerWrapper{
  display: inline-flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.rdrDefinedRangesWrapper{}

.rdrStaticRanges{
  display: flex;
  flex-direction: column;
}

.rdrStaticRange{
  font-size: inherit;
}

.rdrStaticRangeLabel{}

.rdrInputRanges{}

.rdrInputRange{
  display: flex;
}

.rdrInputRangeInput{}

.rdrCalendarWrapper{
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper{
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay{
  margin: 0.833em;
}

.rdrDateDisplayItem{
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input{
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #849095;
  }

.rdrDateDisplayItemActive{
  border-color: currentColor;
}

.rdrDateDisplayItemActive input{
    color: #7d888d
  }

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers{
  font-weight: 600;
}

.rdrMonthAndYearPickers select{
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center
  }

.rdrMonthAndYearPickers select:hover{
      background-color: rgba(0,0,0,0.07);
    }

.rdrMonthPicker, .rdrYearPicker{
  margin: 0 5px
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7
}

.rdrNextPrevButton:hover{
    background: #E1E7F0;
  }

.rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }

.rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth{
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
    padding: 0;
  }

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #1d2429
}

.rdrDay:focus {
    outline: 0;
  }

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span{
  font-weight: 500
}

.rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #3d91ff;
  }

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
      background: #fff;
    }

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
          color: rgba(255, 255, 255, 0.85);
        }

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected{
  left: 2px;
  right: 2px;
}

.rdrInRange{}

.rdrStartEdge{
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge{
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected{
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
   border-top-right-radius: 1.333em;
   border-bottom-right-radius: 1.333em;
   border-right-width: 1px;
   right: 0px;
 }

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview{
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview{
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0px;
}

.rdrDefinedRangesWrapper{
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
    color: currentColor;
    font-weight: 600;
  }

.rdrStaticRange{
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff
}

.rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
      background: #eff2f7;
    }

.rdrStaticRangeLabel{
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges{
  padding: 10px 0;
}

.rdrInputRange{
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput{
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122)
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
  }

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  content: '';
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive{
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span{
    color: #d5dce0;
  }

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
    display: none;
  }

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span{
    color: #aeb9bf;
  }

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
    filter: grayscale(100%) opacity(60%);
  }

.rdrMonthName{
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}

.vsx-datetime-picker {
  border: 1px solid #bfc0c2;
  border-radius: 3px;
  overflow: hidden;
}
.vsx-datetime-picker .rdrDateDisplayWrapper {
  display: none;
}
.vsx-datetime-picker .rdrMonthAndYearWrapper {
  padding-top: 5;
  height: unset;
}
.vsx-datetime-picker .rdrMonth {
  padding-bottom: 0.833em;
}
.vsx-datetime-picker .rdrCalendarWrapper {
  border-radius: 3px;
}
.vsx-datetime-picker .vsx-time-picker {
  border-left: 1px solid #bfc0c2;
  overflow: hidden;
}
.vsx-datetime-picker .vsx-time-picker .vsx-render-time-item {
  color: #1d2429;
  font-size: 12px;
}
.vsx-datetime-picker .vsx-time-picker .vsx-render-time-item.disabled {
  background: rgb(248, 248, 248);
  color: #aeb9bf;
  cursor: not-allowed !important;
}
.vsx-datetime-picker .vsx-time-picker.disabled {
  background: rgba(248, 248, 248, 0.85);
  cursor: not-allowed !important;
}
.vsx-datetime-picker .vsx-time-picker.disabled .vsx-time-list {
  background: rgba(248, 248, 248, 0.85);
}
.vsx-datetime-picker .vsx-time-picker.disabled .vsx-render-time-item {
  cursor: not-allowed !important;
}
.vsx-datetime-picker .vsx-time-picker.disabled .vsx-render-time-item {
  background: rgb(248, 248, 248);
  color: #aeb9bf;
}
.vsx-datetime-picker .vsx-time-picker.disabled .vsx-render-time-item.selected {
  filter: grayscale(100%) opacity(60%);
}
.vsx-datetime-picker .vsx-time-picker .vsx-time-header {
  box-sizing: border-box;
  height: 45px;
  border-bottom: 1px solid #bfc0c2;
}
.vsx-datetime-picker .vsx-time-picker .vsx-time-list {
  overflow-y: scroll;
  min-height: 210px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}
.vsx-datetime-picker .vsx-time-picker .vsx-time-list .vsx-render-time-item {
  padding: 5px 20px 5px 10px;
  text-align: right;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.vsx-datetime-picker .vsx-time-picker .vsx-time-list .vsx-render-time-item.selected {
  background: rgb(61, 145, 255);
  color: rgba(255, 255, 255, 0.85);
}
.vsx-bs-container {
  text-align: center;
  padding: 64px 32px;
}

.vsx-bs-container.vsx-bs-leftalign {
  display: flex;
  text-align: left;
}

.vsx-bs-container.vsx-bs-leftalign .vsx-bs-img {
  width: 128px;
}

.vsx-bs-container.vsx-bs-leftalign .vsx-bs-content {
  padding-left: 24px;
  padding-top: 16px;
}

.vsx-bs-container.vsx-bs-leftalign h1.vsx-bs-title {
  margin-top: 0;
  margin-bottom: 24px;
}

.vsx-bs-container.vsx-bs-leftalign .vsx-bs-subtitle {
  margin-bottom: 24px;
}

.vsx-bs-container.vsx-bs-leftalign .vsx-bs-subtitle p {
  margin-bottom: 8px;
}

.vsx-bs-img svg {
  width: 128px;
  height: 128px;
  fill: var(--color-icon-placeholder);
}

h1.vsx-bs-title {
  font-size: 1.8rem;
  margin-bottom: 24px;
  margin-top: 32px;
}

.vsx-bs-subtitle {
  color: var(--color-text-default);
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.vsx-bs-description-text {
  color: var(--color-text-subdued);
  margin: 0 auto 24px;
  max-width: 768px;
}

.vsx-bs-button-primary {
  margin: 0 0 16px;
}

.vsx-list-view {
  --item-padding: 7px 8px;
  background-color: var(--color-background-default);
  border-radius: 3px;
}
.vsx-list-view > :first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.vsx-list-view > :last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.vsx-list-view:not(.no-separator) > :not(:last-child) {
  border-bottom: 1px solid #c8ccce;
}
.vsx-list-view .vsx-list-item,
.vsx-list-view .vsx-list-view-header,
.vsx-list-view .vsx-list-view-footer {
  padding: var(--item-padding);
}
.vsx-list-view .vsx-list-item-clickable:hover {
  cursor: pointer;
  background: #eee;
  transition: background linear 0.2s;
}
.vsx-list-view .vsx-list-view-spinner-wrapper {
  text-align: center;
  padding: 50px 0px;
}
.vsx-list-view.no-separator > .vsx-list-item {
  border-bottom: unset;
}
.vsx-multi-file-upload {
  color: var(--color-text-default);
}
.vsx-multi-file-upload .vsx-multi-file-upload-list {
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  border-radius: 3px;
  min-height: 32px;
  position: relative;
}
.vsx-multi-file-upload .vsx-multi-file-upload-list.vsx-multi-file-upload-list-error {
  border-color: var(--color-text-critical);
}
.vsx-multi-file-upload .vsx-multi-file-upload-button {
  min-width: unset;
}
.vsx-multi-file-upload .vsx-multi-file-upload-remove-button {
  transform-origin: right;
  transform: scale(0.8);
}
.vsx-multi-file-upload .vsx-multi-file-upload-info {
  flex-grow: 1;
  text-align: right;
  margin-top: 4px;
}
.vsx-multi-file-upload .vsx-multi-file-upload-filename {
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vsx-multi-file-upload .vsx-multi-file-upload-placeholder {
  text-align: center;
  font-style: italic;
  position: absolute;
  transform: translate(-50%, -50%);
  color: var(--color-text-subdued);
  width: calc(100% - 8px);
  top: 50%;
  left: 50%;
}
.vsx-file-drop-zone[data-active=true] {
  outline: 3px dashed var(--color-interactive-default);
  outline-offset: -3px;
}
.vsx-file-drop-zone.vsx-file-drop-zone-block {
  display: block;
}
.vsx-file-drop-zone.vsx-file-drop-zone-inline {
  display: inline-block;
}
.vsx-radio-group {
  color: var(--color-text-default);
}

.vsx-radio-group-expanded-row {
  flex-basis: 100%;
}
.vsx-radio-group-expanded-row:empty {
  display: none;
}

.vsx-radio {
  margin: 0;
  cursor: pointer;
}
.vsx-radio:disabled {
  cursor: not-allowed;
}

.vsx-radio-details {
  color: #777;
}

.vsx-radio-expand {
  padding: 12px;
  background: var(--color-background-page);
  border-radius: 4px;
  grid-column: 1/-1;
}

.vsx-radio-label {
  cursor: pointer;
}
.vsx-radio-label[data-disabled=true] {
  cursor: not-allowed;
  color: var(--color-text-subdued-light);
}

.vsx-radio-label-content {
  white-space: nowrap;
}
.vsx-single-file-upload > input {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.vsx-single-file-upload input:disabled + .vsx-single-file-upload-button,
.vsx-single-file-upload input[readonly] + .vsx-single-file-upload-button {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-single-file-upload .vsx-single-file-upload-button {
  height: 20px;
}
.vsx-single-file-upload .vsx-single-file-upload-text {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vsx-single-file-upload .vsx-single-file-upload-text.vsx-single-file-upload-placeholder {
  font-style: italic;
  color: var(--color-text-subdued);
}
.vsx-slider {
  --main-color: var(--color-secondary-gray-2);
  --track-color: var(--color-background-light-3);
  --slider-height: 18px;
  cursor: pointer;
  min-width: 100px;
  min-height: var(--slider-height);
  flex-grow: 1;
}
.vsx-slider.vsx-slider-disabled {
  opacity: 0.4;
}
.vsx-slider.vsx-slider-range .vsx-slider-track.vsx-slider-track-0 {
  background: var(--track-color);
}
.vsx-slider.vsx-slider-range .vsx-slider-track.vsx-slider-track-1 {
  background: var(--main-color);
}
.vsx-slider .vsx-slider-track {
  top: 8px;
  height: 4px;
  background: var(--track-color);
}
.vsx-slider .vsx-slider-track.vsx-slider-track-0 {
  background: var(--main-color);
}
.vsx-slider:hover .vsx-slider-thumb {
  outline: 1px solid var(--color-border-outline-dark);
}
.vsx-slider .vsx-slider-thumb {
  background: var(--color-icon-light);
  width: var(--slider-height);
  height: var(--slider-height);
  border-radius: 100%;
  outline: 1px solid var(--color-border-outline-subdued);
  outline-offset: -1px;
  transform: translateY(1px);
}
.vsx-slider .vsx-slider-mark {
  cursor: pointer;
  top: 6px;
  width: 1.5px;
  height: 8px;
  background-color: var(--mark-color);
}
.vsx-social-icon {
  display: inline-block;
  line-height: 1;
}
.vsx-toggle-switch {
  font-weight: var(--font-font-weight-medium);
}
.vsx-toggle-switch.vsx-toggle-switch-disabled, .vsx-toggle-switch.vsx-toggle-switch-readonly {
  opacity: 0.4;
}
.vsx-toggle-switch.vsx-toggle-switch-disabled .vsx-toggle-input *, .vsx-toggle-switch.vsx-toggle-switch-readonly .vsx-toggle-input * {
  cursor: not-allowed !important;
}
.vsx-toggle-switch.vsx-toggle-switch-disabled .vsx-toggle-switch-label, .vsx-toggle-switch.vsx-toggle-switch-readonly .vsx-toggle-switch-label {
  cursor: not-allowed !important;
}
.vsx-toggle-switch .vsx-toggle-switch-label {
  cursor: pointer;
}
.vsx-toggle-switch .vsx-toggle-input {
  position: relative;
  display: inline-block;
  width: 30px;
  min-width: 30px;
  height: 12px;
  margin: 2px 1px;
}
.vsx-toggle-switch .vsx-toggle-input > input {
  opacity: 0;
  width: 0;
  height: 0;
}
.vsx-toggle-switch .vsx-toggle-input > input:checked + .vsx-toggle-input-slider::before {
  transform: translateY(-2px) translateX(calc(30px - 100% + 1px));
}
.vsx-toggle-switch .vsx-toggle-input > input:checked + .vsx-toggle-input-slider {
  background-color: var(--color-interactive-default);
}
.vsx-toggle-switch .vsx-toggle-input .vsx-toggle-input-slider {
  position: absolute;
  border-radius: 100px;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-border-form-default);
  transition: 0.4s;
}
.vsx-toggle-switch .vsx-toggle-input .vsx-toggle-input-slider::before {
  background-color: var(--color-text-white);
  border: 1px solid var(--color-border-form-default);
  border-radius: 45px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  content: "";
  height: 16px;
  position: absolute;
  transition: 0.2s;
  width: 16px;
  transform: translateY(-2px) translateX(-1px);
}
.vsx-clickable {
  color: var(--color-text-link-default);
  cursor: pointer;
}
.vsx-clickable:hover {
  color: var(--color-text-link-hover);
  text-decoration: underline;
}
.vsx-clickable:disabled {
  color: var(--color-text-subdued-light);
}
.vsx-sliding-panel {
  background-color: var(--color-background-default);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0);
  color: var(--color-text-default);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  transition: all 0.5s;
  z-index: 89;
  border-left: 1px solid #d1d1d2;
  flex-shrink: 0;
  flex-basis: 300px;
  margin-left: 8px;
}
.vsx-sliding-panel[data-active=false] {
  flex-basis: 0px;
  margin-left: 0;
  border-left-width: 0px;
}

.vsx-sliding-panel-page-content {
  flex-grow: 1;
}

.vsx-sliding-panel-header {
  font-weight: 500;
  padding: 14px 12px;
  text-transform: capitalize;
  border-bottom: 1px solid #d1d1d2;
}

.vsx-sliding-panel-section {
  border-bottom: 1px solid #d1d1d2;
  border-top: 1px solid #d1d1d2;
}

.vsx-sliding-panel-dropdown .vsx-sliding-panel-dropdown-label-row {
  cursor: pointer;
}
.vsx-sliding-panel-dropdown .vsx-sliding-panel-dropdown-label-row .vsx-sliding-panel-dropdown-label {
  flex-grow: 1;
  font-weight: 500;
  white-space: nowrap;
}
.vsx-sliding-panel-dropdown .vsx-sliding-panel-dropdown-label-row .vsx-sliding-panel-dropdown-badge {
  border-radius: 10px;
  white-space: nowrap;
}
.vsx-sliding-panel-dropdown .vsx-sliding-panel-dropdown-label-row .vsx-sliding-panel-dropdown-chevron {
  transform: scale(0.55);
}
.vsx-sliding-panel-dropdown .vsx-sliding-panel-dropdown-children[data-expanded=false] {
  display: none;
}
.vsx-tristatecheckbox {
  cursor: pointer;
}
.vsx-tree {
  box-sizing: border-box;
}
.vsx-tree .vsx-hidden {
  display: none;
}
.vsx-tree .vsx-node {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0.2em 0;
}
.vsx-tree .vsx-summary {
  display: list-item;
  outline: none;
}
.vsx-tree .vsx-leafContent:hover {
  background: linear-gradient(to left, transparent 2%, #E6E6E6 50%, transparent 98%);
}
.vsx-tree .vsx-treeChildren {
  margin-left: 1.5rem;
}
.vsx-tree .vsx-tree-bold-highlight {
  font-weight: bold;
}
.vsx-tree .vsx-treecheckbox {
  padding-left: 10px;
  box-sizing: border-box;
}
input[type="range"] {
  --thumbSize: 16px;
  --trackSize: 4px;
  --thumbBg: var(--white);
  --thumbBorder: 1px solid var(--silver);
  --thumbBorderFocus: 1.5px solid var(--warm-grey);
  --trackBg: var(--silver);
  --progressBg: var(--warm-grey);

  /* webkit progress workaround */
  --webkitProgressPercent: 0%;
}

input[type="range"] {
  appearance: none;
  height: var(--thumbSize);
  width: 100%;
  margin: 8px 0;
  padding: 0px 10px 0px 0px;
}

input[type="range"]:focus {
  outline: none;
}

/* Slider Thumb */

/* Chrome */
input[type="range"]::-webkit-slider-thumb {
  appearance: none;

  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: var(--thumbBorder);

  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}

/* Firefox */
input[type="range"]::-moz-range-thumb {
  appearance: none;

  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: var(--thumbBorder);

  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}

/* IE */
input[type="range"]::-ms-thumb {
  appearance: none;

  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: var(--thumbBorder);

  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}

/* Chrome */
input[type="range"]:hover::-webkit-slider-thumb {
  border: var(--thumbBorderFocus);
}

/* Firefox */
input[type="range"]:hover::-moz-range-thumb {
  border: var(--thumbBorderFocus);
}

/* IE */
input[type="range"]:hover::-ms-thumb {
  border: var(--thumbBorderFocus);
}

/* Slider Track */

/* Chrome */
input[type="range"]::-webkit-slider-runnable-track {
  height: var(--trackSize);
  background-image: linear-gradient(
    90deg,
    var(--progressBg) var(--webkitProgressPercent),
    var(--trackBg) var(--webkitProgressPercent)
  );
  border-radius: calc(var(--trackSize) / 2);
}

/* Firefox */
input[type="range"]::-moz-range-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}

/* IE */
input[type="range"]::-ms-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}

/* Slider Progress */

/* Firefox  */
input[type="range"]::-moz-range-progress {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}

/* IE */
input[type="range"]::-ms-fill-lower {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}

/* Slider Input */

span.percentage-input-wrapper span.percentage-input {
  appearance: none;
  -webkit-rtl-ordering: logical;
  outline: none;
  -ms-writing-mode: none;
      writing-mode: none;

  display: inline;
  margin: 0em;
  /* padding: 1px; */

  border: none;
  width: 4ch;

  color: -internal-light-dark-color(black, white);
  background-color: -internal-light-dark-color(white, black);

  font: 400 11px system-ui;
  cursor: text;

  font-size: inherit;
  line-height: inherit;

  font-family: inherit;
  text-align: start;
  letter-spacing: normal;
  text-indent: 0px;
  text-rendering: auto;
  word-spacing: normal;
  text-shadow: none;
  text-transform: none;
}

span.percentage-input-wrapper {
  display: inline-block;
  width: 60px;
  height: 32px;
  margin: 0;
  padding: 4px 8px;

  color: var(--color-text-default);
  background-color: var(--color-background-default);
  transition: border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out;

  font-size: 1.4rem;
  font-weight: 400;

  text-align: right;
}

.form-horizontal .form-group-vertical {
  margin-right: 0;
  margin-left: 0;
}

.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  display: table;
  content: ' ';
}

.form-horizontal .form-group:after {
  clear: both;
}

.form-group-buttons {
  text-align: right;
}

.form-group-buttons .form-button {
  margin-left: 10px;
}

.form-label-vertical {
  margin-bottom: 0.3em;
}

.form-label-text-vertical {
  font-size: 1.1em;
}

.form-group-static-text {
  line-height: 32px;
  padding: 1px 6px;
}

.form-group-required-sign {
  padding-left: 0.3em;
  padding-right: 0.3em;
  color: var(--color-text-critical);
}

.form-group-error-span {
  display: block;
  color: var(--color-text-critical);
  top: 6px;
  position: relative;
}

.has-error .control-label {
  color: var(--color-text-critical);
}

.has-error .form-control {
  border-color: var(--color-border-outline-error);
}

.form-group-radio-label {
  font-size: 1em;
  cursor: pointer;
  cursor: hand;
}

.cj-form-input.form-group-radio-input {
  min-height: 24px;
  margin-bottom: 0;
}

.has-help-icon {
  display: inline;
  overflow: visible;
  float: left;
}

.help-icon-form-component {
  margin-left: 15px;
}

.clipboard-input {
  color: #9e868e;
  background-color: inherit;
  cursor: default;
}

.clipboard-button {
  padding-top: 6px;
  padding-right: 12px;
}

.clipboard-button:focus {
  outline: 0;
}

.row-destinationUrl .tooltip-inner {
  width: 350px;
  max-width: 350px;
}

.fieldset-title {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

.fieldset-panel {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

fieldset.group {
  margin: 0;
  margin-bottom: 1.25em;
  padding: 0.125em;
}

fieldset.group ul.checkbox {
  margin: 0;
  padding: 0;
  margin-left: 20px;
  list-style: none;
}

fieldset.group ul.checkbox li input {
  margin-right: 0.25em;
}

fieldset.group ul.checkbox li {
  border: 1px transparent solid;
  display: inline-block;
  width: 12em;
}

fieldset.group ul.checkbox li:hover,
fieldset.group ul.checkbox li.focus {
  background-color: lightyellow;
  border: 1px gray solid;
  width: 12em;
}

.vsx-validation-error {
  color: var(--color-text-critical);
  margin-top: 4px;
  font-size: 1.3rem;
}

.vsx-field-help {
  color: #777;
  margin-top: 4px;
  font-size: 1.3rem;
}

.vsx-field-optional {
  color: #777;
  font-size: 1.3rem;
  font-style: italic;
}

.vsx-label-font-bold {
  font-weight: 500;
}

.vsx-label-font-normal {
  font-weight: normal;
}

.vsx-field .form-group-required-sign {
  padding-left: 0;
}

.vsx-expandable-checkbox-list,
.vsx-expandable-item {
  color: var(--color-text-default);
}

.vsx-expandable-checkbox {
  margin: 0;
  cursor: pointer;
}

.vsx-expandable-chevron {
  font-size: 14px;
  transform: scale(1.3);
}

.vsx-expandable-item-row {
  cursor: pointer;
}

.vsx-expandable-item-children[data-disabled=true],
.vsx-expandable-item-label[data-disabled=true] {
  color: var(--color-text-subdued-light);
}

.vsx-expandable-item-children[data-expanded=false] {
  display: none;
}
.vsx-pagination {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-top: 1px solid #e3e6e8;
}

.vsx-page-control {
  display: flex;
  align-items: center;
}

.vsx-page-control > * {
  margin: 0 4px;
}

.vsx-pagination-paging {
  font-size: 14px;
}

.vsx-rows-per-page-container {
  width: 150px;
}

.vsx-react-select__menu.vsx-react-select__menu {
  z-index: 100;
}

.vsx-react-select-rows-per-page {
  margin-bottom: 0;
}

.vsx-rows-per-page-container > div {
  margin-bottom: 0;
}
.vsx-drawer {
  position: fixed;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-background-dark-2);
  z-index: 9999;
  transition: height 0.5s;
}

.vsx-drawer.vsx-drawer-open {
  height: 64px;
}
.vsx-layout-section {
  --separator: 1px solid var(--color-border-light);
  border-top: var(--separator);
  border-bottom: var(--separator);
}
.vsx-layout-section:not(.disable-form-page-block-adjust) {
  margin-left: -32px;
  margin-right: -32px;
}
.vsx-layout-section .vsx-layout-section-title {
  font-size: var(--font-font-size-14);
  font-weight: var(--font-font-weight-medium);
  cursor: pointer;
}
.vsx-layout-section .vsx-layout-section-title.vsx-layout-section-title-grow {
  flex-grow: 1;
}
.vsx-layout-section .vsx-layout-section-children {
  padding: 16px 32px;
}

.vsx-layout-section-no-bottom-border,
.vsx-layout-section:has(+ .vsx-layout-section) {
  border-bottom-width: 0;
}
